
.containerRoleEdit {
	margin-top:10px;
    padding: 10px;
    width:500px;
    border: 1px solid;
    border-color: rgb(98, 90, 104);
    border-radius:0.5rem;
	margin-right: auto;
    margin-left: auto;
}
.form-control {
    border:2px solid rgb(176, 187, 195);
	font-size:0.9em;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
}
/*
.btn-primary {
	font-size:1em;color:white;font-weight:600;background-color: rgb(57, 145, 207);border-radius:4px;width:65%;height:30px;
}
.btn-secondary {
	font-size:1em;color:white;font-weight:600;background-color: rgb(143, 133, 133);border-radius:4px;width:30%;height:30px;
}
#textarea {
    font-size:0.9em;
    width: 100%;
    height: 50px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
}
*/

/*
#formEditRoleUser input {
    font-size:0.9em;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
}
*/
.containerButtonRoleEdit{
    display: flex;
    margin-top:15px;
}
.containerButtonRoleEdit div{
   border:1px none red;padding:2px;
}
#divButtonRoleEditSave {
width:70%;
}
#divButtonRoleEditCancel {
width:30%;
}
/* Formatowanie butonów zapisz role cancel */
.buttonRoleUserAddRole {
    font-size:1em;color:white;font-weight:600;background-color: rgb(57, 145, 207);border-radius:4px;width:100%;height:30px;
}
.buttonRoleUserReset {
    font-size:1em;color:white;font-weight:600;background-color: rgb(143, 133, 133);border-radius:4px;width:100%;height:30px;
}
/* END Formatowanie butonów zapisz role cancel */
.messageResponseBox {
    padding:5px;
    padding-top:8px;
    height:40px;
    font-weight: 600;
    border-radius: 7px;
    text-align: center;
}
#messageResponseError {
    background-color: rgb(210, 69, 69);
    color: white;
}
#messageResponseOK {
    background-color: rgb(29, 123, 64);
    color: white;
}


/*
table {
	border-collapse: collapse;
}
table,
th,
td {
  border: 1px solid rgb(107, 102, 102);
  padding:5px;
}
#tableDataListRoles {
    margin-top: 20px;
	width:100%;
}
#tableDataListRoles th{
    color: white;
    background: rgb(143, 133, 133);
}

td {
    border: 1px solid #ccc;
    width: 2em; 
    max-width: 2em; 
    height: 2em; 
    line-height: 2em; 
    text-align: center; 
    overflow: hidden; 
}
*/




table {
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
}
th {
  background-color: rgb(57, 145, 207);
  color: #FFFFFF;
}
th, td {
  padding: 10px;
  border: 1px solid #666161;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
/* required css to make rounded table (below) */
tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
tr:first-child th:last-child {
  border-top-right-radius: 10px;
}
tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}












#tableRolesButtonDelete {
    width:60px;
    background-color: rgb(249, 4, 4);
    border-color: rgb(249, 4, 4);;
    border-radius:0.3rem;
    font-weight: 600;
    color: white;
}
#tableRolesButtonEdit {
    width:60px;
    background-color: rgb(116, 59, 202);
    border-color: rgb(116, 59, 202);
    border-radius:0.3rem;
    font-weight: 600;
    color: white;
}
/* END Tabela z rolami użytkownika */
.margintop10px {
    margin-top: 10px;
}
.page-link {
	font-size: 0.8em;
}
@media only screen and (max-width: 800px) {
  .containerRoleEdit {
    width:90%;
}
}