@import-normalize
body {
  color: blue;
  font-family:"Arial";
font-size: 0.8em;
  margin: 0;
 padding: 0;
}

p {
	margin-top:4px;
	font-size: 0.8em;
	font-family:"Arial";
}
a {
  font-size: 0.8em;
	font-family:"Arial";
}
/* unvisited link */
a:link {
  color: #626667;
}

/* visited link */
a:visited {
  color: #626667;
}

/* mouse over link */
a:hover {
  color: black;
}

/* selected link */
a:active {
  color: blue;
}
ul {
	margin-top:0px;
	margin-bottom:0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Formatowanie tabel z danymi */
.table-data {
  margin-top:10px;
  font-size: 0.8em;
	font-family:"Arial";
}

/* Formatowanie formularzy */
label {
  font-size: 0.8em;
	font-family:"Arial";
}
input {
  padding:5px;
  font-size: 0.8em;
	font-family:"Arial";
  width: 100%;
  box-sizing: border-box;
	border:2px solid rgb(154, 147, 147);
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}

textarea {
  padding:5px;
  font-size: 0.8em;
	font-family:"Arial";
  width: 100%;
  box-sizing: border-box;
	border:2px solid rgb(154, 147, 147);
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}
button {
	cursor: pointer;
}
/* Formatowanie butonów zapisz role cancel */
.buttonSaveData {
    font-size:1em;
    color:white;
    font-weight:600;
    background-color: rgb(57, 145, 207);
    border-radius:4px;
    width:100%;
    height:40px;
}
.buttonResetData {
    font-size:1em;
	color:white;
	font-weight:600;
	background-color: rgb(143, 133, 133);
	border-radius:4px;
	width:100%;
	height:40px;
}
.form-validation-error {
  font-size: 0.8em;
	font-family:"Arial";
  font-weight:600;
  color:red;
}




/* Formatowanie kontenerów na tabelki i inne dane */
.container-center-width-500 {
  background-color: rgb(238, 238, 245);
  margin-top:10px;
  padding: 10px;
  width:500px;
  border: 2px solid;
  border-color: rgb(189, 184, 193);
  border-radius:0.5rem;
	margin-right: auto;
  margin-left: auto;
}
.div-inside-container-flexbox{
	height:130px;
	background-color: white;
  border: 2px solid;
	border-color: rgb(151, 144, 154);
  border-radius:0.5rem;
	margin: 5px;
	padding: 5px;
	font-size: 0.8em;
}
.container-flexbox {
	display: flex;
}
/* END Formatowanie kontenerów na tabelki i inne dane */

/* Formatowanie ramek na response OK i response NOT OK */
.messageResponseBox {
  font-size: 0.8em;
font-family:"Arial";
  margin-bottom:5px;
}


.container-flexbox-1 {
	display: flex;
	border:0px solid blue;
	width:100%;
	font-size: 0.8em;
}
.div-inside-container-flexbox-1{
	border:0px solid red;
	border-color: black;
	margin: 0px;
	padding: 2px;
	font-size: 0.8em;
	width:45%;
}
.div-inline-block {
	display: inline-block;
	vertical-align: top;
}
.middle > * {
  vertical-align: middle;
}

/* Ustawienia ogólne np szerokosc wysokosc wyrównanie */
.cursor-pointer {
	cursor:pointer;
}
.width70percent {
	width:70%;
}
.width80percent {
	width:80%;
}
.width100percent {
	width:100%;
}
.alignRight {
	text-align:right;
}
.center {
	display:block;
	margin-left: auto;
	margin-right: auto;
}
.center-text {
	text-align: center;
}
.margin-top10 {
	margin-top:10px;
}
.margin-top20 {
	margin-top:20px;
}
.middle > * {
  vertical-align: middle;
}



/* Admin Footer */
#admin-footer {
  font-family:"Arial";
  text-align:center;
  font-size: 0.7em;
}
