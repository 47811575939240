.modal::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.modal {
  width:400px;
  height:400px;
  padding: 10px;
  border: 0;
  border-radius: 0.5rem;
  position: relative;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
}

.modal-confirm-delete-role-user {
  width:400px;
  height:320px;
  padding: 10px;
  border: 0;
  border-radius: 0.5rem;
  position: relative;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
}

.modal-close-btn {
  font-size: 0.75em;
  position: absolute;
  top: 0.25em;
  right: 0.25em;
}
