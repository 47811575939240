


/*
 * add-edit-user.component.js
 */
.container-add-edit-user {
	width: 500px;
}
/*
 *END add-edit-user.component.js
 */



#categorySingleEntry {
    width: 400px;
}
.input-edit {
    display: inline-block;
    width: 50%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.textarea-edit {
    display: inline-block;
    width: 70%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.label-edit{
    width: 180px;
}
.buttonsave {
    height:50px;
}
#noteHeader {
    text-align:left;
    font-size: 0.7em;
  }
  #footer {
    text-align:center;
    font-size: 0.7em;
  }
  #header-hr {
    max-width: 450px; 
    margin-left:0;
  }
  #footer-hr {
    margin: 0 auto;
	width: 98%;
	display: block;
	
  }
  hr {
	  margin-top:20px;
	  border: 1px solid rgb(128, 128, 128);
  }
 
  .padding-top-20px {
    padding-top:20px;
  }
  #modalEdit {
    padding:5px;
  }

  /*
   * Formatowanie komponentu mainCointainer
   */

   #mainCointainer {
    height:400px;
    width: 98%;
    border: 1px solid red;
   }
   
.middle > * {
  vertical-align: middle;
}
