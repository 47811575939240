@import-normalize
p {
	margin-top:0em;
}
/*
TopNavBar
*/
#topnavbarContainer {
	border: 2px solid rgb(162, 162, 170);
	background-color: rgb(204, 214, 230);
	border-radius: 0.25rem;
	padding: 3px;
}  
#topnavbarContainerLeft {
	width: 50%;  
	text-align: left;
	border:0px solid blue;
}
#topnavbarContainerRight {
	width: 50%;  
	text-align: right;
	border:0px solid green;
}
.bordercolorblue {
	  border:1px solid blue;
}
.bordercolorgreen {
	  border:1px solid green;
}
.linkTopnavbarNotActive {
	color:red;
}
.linkTopnavbarActive {
	color: black;
	font-weight: 900;
}
/* END TopNavBar */
  
.containerFlexBox {
	display: flex;
}
.containerFlexBoxRight {
	display: flex;
	justify-content: right;
}
.containerFlexBox div{
	padding-left: 10px;
	padding-right: 10px;
	padding-top:5px;
	padding-bottom:5px;
}


  